const state = {
  route: {},
  curPlayItem: null,
  svaeProgress: "",
  curPlayTime: 0,
  curPlayProgress: 0,
  seektime: 0,
  screenshot: null,
  userName: null,
  // applyStates: null,
  // applyInfos: {},
  unreadCount: 0,
  coursestate: null,
  isstudent: undefined,
  isSpecialWork: false, //控制专题班课程列表中作业撰写的状态
  specialInfo: null,
  applyStateGz: 0, //是否是班导
};

export default state;
