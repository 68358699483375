function checkLoginState(state = true, isreloadCurrentPage = true, isback = false) {
  let loginUrl = dsf.url.getMobileLogin();
  return new Promise(resolve => {
    if (dsf.getToken()) {
      resolve(true);
    }
    else {
      let sourceUrl = dsf.config.setting_public_login_dialog;
      sessionStorage.clear();
      const isMobile = dsf.client.mobile();
      if (isMobile) {
        dsf.layer
          .confirm({ message: "当前操作需要登录, 去登录?", title: "提示", confirmButtonText: "是", cancelButtonText: "否" })
          .then(() => {
            dsf.cookies.set("registerSourceRoute", location.hash);
            location.replace(loginUrl);
          })
          .catch(() => {
            console.log("取消重新登录");
            if (isback) return location.replace(dsf.config.setting_public_mobileindex_url);
          });
        resolve(false);
      }
      else {
        if (sourceUrl && state) {
          this.$openDialog({
            title: "",
            noTitle: true,
            width: "800px",
            height: "370px",
            class: "test",
            dialogArgs: {
              isreloadCurrentPage: isreloadCurrentPage,
            },
            params: {
              path: sourceUrl,

              loginSuccess() {
                resolve(true);
              },
              loginFail() {
                resolve(false);
              },
            },
            onClosed() {
              resolve(false);
            },
          });
        } else {
          let url = "http://qifuyun.natappvip.cc/login3_pop.jsp?platform=mcwlxy";
          this.$open({
            url,
          });
        }
      }
    }
  });
}

export default checkLoginState;
