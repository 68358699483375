dsf.mix(
  true,
  dsf.config,
  {
    webRoot: {
      //默认请求路径根
      default: "/inc/",
      //dsfa4.0
      dsfa: "/",
      teas: "/",
      nc: "/inc/",
      exam: "/exam/",
      bbs: "/bbs/",
    },
    file: {
      preViewServer: "/inc/",
    },
    //设计器scss编译文件
    designer: {
      //是否过滤组件版本
      controlsVersionFilter: false,
      defaultControlGroup: [],
      scssDir: process.env.NODE_ENV === "production" ? "/inc/static/" : "/static/",
      //设计器编辑页面自定义样式时的基础样式变量
      // scss: ["platform/variable.scss", "platform/themes.scss", "platform/mixins.scss", "project/variable.scss", "project/themes.scss", "project/mixins.scss"]
      scss: ["platform/variable.scss", "platform/themes.scss", "platform/mixins.scss"],
    },
    //当前运行类型：project--项目
    runType: "product",
    assetsDir: "./static/",
    setting_nc_image_teacher_header_img: '111111',
    //是否开启登录校验
    setting_public_check_login_state: false,
    //登录弹窗地址
    setting_public_login_dialog: "#/pc/nc/pagelogin/dialogLogin",
    //购物车地址
    setting_public_shoppingcars_url: "/page.html#/pc/nc/page/pc/shop/shoppingcars",
    //用户注册地址
    setting_public_login_register: "#/pc/nc/page/pc/login/registerModelTwo",
    //移动端用户注册地址
    setting_public_mobile_login_register: "/mobile/nc/page/app/register/mobile-productRegister",
    //用户忘记密码
    setting_public_login_forget_password: "/page.html#/pc/dsfa/platformhome/forgetPassword",
    //全局检索地址
    setting_public_search_url: "./page.html#/pc/nc/pageglobalsearch/globalSearch",
    //个人中心地址
    setting_public_usercenter_url: "./ncIndex.html#/pc/nc/pagepersonalCenter/index",
    //pc端首页地址
    setting_public_pcindex_url: "ncIndex.html#/pc/nc/pagehome/index",
    //移动端首页地址
    // setting_public_mobileindex_url: "/inc/mobile-index.html?appid=nc.page.app.manager#/mobile/nc/page/app/home/mobile-index",
    setting_public_mobileindex_url: "/inc/mobile-index.html?appid=nc.page.app.manager#/mobile/nc/page/app/gzyddpage/mobile-gzhome",
    //移动端课程播放地址
    setting_public_mobilecourse_url: "/mobile/nc/page/app/course/mobile-coursePlayer?id=",
    // setting_public_mobileindex_url: "mobile-index.html#/mobile/nc/page/app/home/mobile-index?corpId=ding58a6e4aef9bb5cfa35c2f4657eb6378f",
    //默认皮肤
    setting_public_default_theme: "red-theme",
    //默认字体大小
    setting_public_default_size: "normal",
    //默认顶部导航栏是否为窄条
    setting_public_default_header_mini: false,
    //页面右下角是否显示设计器跳转图标
    setting_public_gotoicon: "1",
    //登录成功后进入页面地址
    setting_public_login_success_to: "./index.html",
    //登陆成功后跳转页面
    setting_public_mobile_login_success_to: "./mobile-index.html",
    //登陆页地址
    setting_public_pc_login_page: "ncIndex.html#/pc/nc/pagehome/index",
    setting_public_mobile_login_page: "./mobile-index.html#/mobile/ncLogin",
    // setting_public_mobile_login_page: "./mobile-index.html#/mobile/gzLogin",
    //移动端logo
    // setting_public_mobile_logo_image: "./static/img/product/logo.png",
    //项目logo图片地址
    setting_public_logo_image: "",
    //项目名称
    setting_public_logo_title: "",
    //默认用户头像
    // setting_public_user_default_header: require("./assets/imgs/icon-default-avatar.png"),
    setting_public_user_default_header: require("./assets/imgs/user_default.png"),
    //默认用户卡片头像
    // setting_public_user_default_card_header: require("./assets/imgs/icon-default-avatar.png"),
    setting_public_user_default_card_header: require("./assets/imgs/user_default.png"),
    // 默认师资头像
    setting_public_teacher_default_header: require("./assets/imgs/teacher_default.png"),
    //默认图片
    setting_public_card_default_img: require("./assets/imgs/defaultCardImage.png"),
    //菜单请求接口
    setting_public_home_menu_src: "/menu/getTree",
    //网络学院门户首页菜单接口
    setting_public_index_menu_src: "/nc/menu/home2",
    //默认预览
    setting_public_file_host: "/",
    // 流程提示弹窗倒计时间，默认为3
    setting_flow_count_down_time: 3,
    //错误图片处理
    setting_error_img: require("./assets/imgs/404.png"),
    //是否调用全局系统接口
    setting_load_global_interface: true,
  },
  window.$$serverConfig ? window.$$serverConfig : {},
  window.$$config ? window.$$config : {}
);
window.$$webRoot = dsf.config.webRoot;
