function toExamPage(params, callback) {
  const loading = dsf.layer.loading();
  const examUrl = "ncexam/api/getExamUrl";
  this.dsf.http
    .get(examUrl, params)
    .done(({ data, message, success }) => {
      if (success) {
        const path = dsf.url.getWebPath(data, $$webRoot.exam);
        const path2 = dsf.url.getWebPath("/exam/index.html#" + data, $$webRoot.exam);
        if (callback&&dsf.isFunction(callback)) {
          callback(data);
        } else {
          // dsf.layer.openWindow(":" + path);
          this.$open({
            url: ":" + path,
          });
        }
      } else {
        dsf.layer.message(message || "获取考试异常", false);
      }
    })
    .error(err => {
      dsf.layer.message(err.message || "获取考试异常", false);
    })
    .always(function() {
      dsf.layer.closeLoading(loading);
    });
}

export default toExamPage;
