import * as types from "./mutation-types";

const mutations = {
  [types.SET_CURRENT_PLAY_TIME](state, data) {
    state.curPlayTime = data;
  },
  [types.SET_CURRENT_PLAY_PROGRESS](state, data) {
    state.curPlayProgress = data;
  },
  [types.SET_CURRENT_PLAY_SEEKTIME](state, data) {
    state.seektime = data;
  },
  [types.SET_CURRENT_PLAY_ITEM](state, data) {
    state.curPlayItem = data;
  },
  [types.PLAYER_HAS_PLAY](state, data) {
    state.svaeProgress = data;
  },
  [types.PLAYER_SET_SCRRENSHOT](state, data) {
    state.screenshot = data;
  },
  [types.Set_Special_Apply_Gz](state, data) {
    state.applyStateGz = data.success;
  },
  [types.Set_Special_Apply_Info_gz](state, data) {
    state.applyInfo = data;
  },
  // [types.Set_Special_Applys](state, data) {
  //   state.applyStates = data.success;
  // },
  // [types.Set_Special_Apply_Infos](state, data) {
  //   state.applyInfos = data;
  // },
  [types.Set_Unread_Count](state, data) {
    state.unreadCount = data.unreadCount;
  },
  [types.Set_Course_State](state, data) {
    state.coursestate = data.state;
    state.specialInfo = data;
  },
  [types.Set_IS_Student](state, data) {
    state.isstudent = data;
  },
};

export default mutations;
